import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// services
import SmoothScrolling from "../services/anchorLink";

// share
import ShareInfomationPageTemplate from "./share-infomation-page";

export const BeautyMedicalTreatmentPageTemplate = ({
  title,
  content,
  contentComponent,
}) => {
  const [selectedTabIndex, setTabIndex] = useState(0);
  const handleSelect = (index, last) => {
    setTabIndex(index);
  };

  return (
    <>
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link to={"/"} className="c-bread__link">
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">美容診療</div>
          </div>
        </div>
      </div>

      <Tabs
        className="c-tabs"
        onSelect={handleSelect}
        selectedIndex={selectedTabIndex}
      >
        <div className="c-contents-tab__outer">
          <div className="c-contents-tab">
            <TabList className="c-contents-tab__list">
              <Tab
                className={`c-contents-tab__item ${selectedTabIndex === 0 &&
                  "is-active"}`}
              >
                <span className="c-contents-tab__text">女性料金</span>
              </Tab>
              <Tab
                className={`c-contents-tab__item ${selectedTabIndex === 1 &&
                  "is-active"}`}
              >
                <span className="c-contents-tab__text">男性料金</span>
              </Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
          <div data-tab-target="#tab1">
            {/* =========== レーザーフェイシャル【しみ・そばかす・にきび治療】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_first_laser_facial"
            >
              <div className="l-main-content-hero__inner">
                <p className="c-text u-mb30">
                  価格は全て税込価格で表示しています
                </p>
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    レーザーフェイシャル【しみ・そばかす・にきび治療】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== レーザーフェイシャル【しみ・そばかす・にきび治療】 ========== */}

            {/* =========== レーザーフェイシャル+ケミカルピーリング  【にきび・にきび痕】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_laser_facial_and_chemical_peeling"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    レーザーフェイシャル + ケミカルピーリング
                    【にきび・にきび痕】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,830</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥45,440</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔(部分)</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥35,640</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== レーザーフェイシャル+ケミカルピーリング  【にきび・にきび痕】 ========== */}

            {/* =========== Vビーム+ケミカルピーリング ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_v_beam_and_chemical_peeling"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vビーム + ケミカルピーリング【にきび痕・赤にきび】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥28,600</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥77,220</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥15,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥41,580</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Vビーム+ケミカルピーリング ========== */}

            {/* =========== Vビーム にきび ・にきび痕 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_v_beam"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vビーム にきび ・にきび痕
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥24,200</p>
                        </div>
                      </div>

                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Vビーム にきび ・にきび痕 ========== */}

            {/* =========== Vビーム 赤ら顔 ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_v_beam"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vビーム 赤ら顔
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔(部分)</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥10,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== Vビーム 赤ら顔 ========== */}

            {/* =========== Vビーム 血管拡張 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_v_beam"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vビーム 血管拡張
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">小鼻〜鼻下</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥5,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                      </div>

                      {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
                      {/* <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">下肢</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥20,000〜</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Vビーム 血管拡張 ========== */}

            {/* =========== ケミカルピーリング 【にきび・にきび痕・黒ずみ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_chemical_peeling"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ケミカルピーリング 【にきび・にきび痕・黒ずみ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥7,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥21,950</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">背中半分</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,550</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥31,190</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両ワキ</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥6,050</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥17,240</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== ケミカルピーリング 【にきび・にきび痕・黒ずみ】 ========== */}

            {/* =========== カーボンピーリング【毛穴詰まり・毛穴】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_carbon_peeling"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    カーボンピーリング【毛穴詰まり・毛穴】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥56,100</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥5,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥15,680</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬＋鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥25,080</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== カーボンピーリング【毛穴詰まり・毛穴】 ========== */}

            {/* =========== PQAge evolution plus ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_e_p_q_age_evolution_plus"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    PQAge evolution plus【にきび・リフトアップ・小じわ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">初回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体/デコルテ</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥15,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥56,980</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">首/手の甲</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,580</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥12,100</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,550</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体＋首/背中上部</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥24,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥89,430</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">ボディ (15×20cm）</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,090</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥18,260</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥67,540</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== PQAge evolution plus ========== */}

            {/* =========== ビタミンCイオン導入 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_vitamin_c_ion"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ビタミンCイオン導入 【にきび・炎症・美白】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥2,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥6,270</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">背中半分</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,300</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥9,410</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== ビタミンCイオン導入 ========== */}

            {/* =========== トラネキサム酸イオン導入 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_tranexamic_acid_ion"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    トラネキサム酸イオン導入 【肝斑・にきび痕・くすみ・美白】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,850</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥10,980</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== トラネキサム酸イオン導入 ========== */}

            {/* =========== イオン導入セット ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_ion_introduction"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    イオン導入セット【ビタミンCイオン導入＋トラネキサム酸イオン導入】
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥27,230</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== イオン導入セット ========== */}

            {/* =========== E-plus SR ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_e_plus_sr"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus SR【しみ・そばかす・赤ら顔】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">頬のみ</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus SR ========== */}

            {/* =========== E-plus Sublime ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_e_plus_sublime"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus　sublime/ST【たるみ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥27,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥74,250</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus Sublime ========== */}

            {/* =========== E-plus IR ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_e_plus_ir"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus IR【深いしわ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">しわ1部位</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">しわ3部位</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus IR ========== */}

            {/* =========== E-plus motifモード ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_e_plus_motif"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus sublime＋ST【小じわ・毛穴】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">頬〜顎下</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus motifモード ========== */}

            {/* =========== 年齢毛穴セット【E-plus　sublime+motif】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_e_plus_sublime_motif"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    年齢毛穴セット【E-plus　sublime+motif】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 年齢毛穴セット【E-plus　sublime+motif】 ========== */}

            {/* =========== アンチエイジングセット【E-plus Sublime+IR】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_anti_aging_set"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    アンチエイジングセット【E-plus Sublime+IR】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔+しわ3部位</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== アンチエイジングセット【E-plus Sublime+IR】 ========== */}

            {/* =========== E-plus Sublime+IR ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_e_plus_subilme_ir"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus Sublime+IR
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔+しわ3部位</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus Sublime+IR ========== */}

            {/* =========== E-plus Triniti(SR+Sublime+motifモード) ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_e_plus_triniti_sr_sublime_motif"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus Triniti(SR+Sublime+motifモード)
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥60,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥163,350</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥257,130</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">SR頬のみ＋他顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥52,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥142,560</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥224,400</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus Triniti(SR+Sublime+motifモード) ========== */}

            {/* =========== イントラジェン 【たるみ・小じわ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_intragen"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    イントラジェン 【たるみ・小じわ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥148,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体＋首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥71,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥193,050</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== イントラジェン 【たるみ・小じわ】 ========== */}

            {/* =========== HIFU【リフトアップ・たるみ・しわ・小じわ】1 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_hifu_01"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    HIFU【リフトアップ・たるみ・しわ・小じわ】
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      High Intensity Focused
                      Ultrasoundの略称で、「高密度焦点式超音波」という意味です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      一転に集中させた超音波を真皮・SMAS層・皮下組織や脂肪層に照射することで、皮膚の表面を火傷させることなく熱で引き締める治療法で、皮膚表面にはダメージを与えることなく、顔のたるみを引き締めることができる新しいリフトアップ治療機器です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      一時的な赤み、熱感、火傷、色素沈着、疼痛
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※光線に過敏方又はアレルギーの方、光過敏の副作用があるお薬を内服されている方は施術をお断りさせていただく場合がございます。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/HIFU-18c942f975224a51960b87123fe2496b"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">初回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥66,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥178,200</p>
                        </div>
                      </div>
                      <div className="c-table__body">
                        <div className="c-table__body__row" key={v4()}>
                          <div className="c-table__body__item">
                            <p className="c-table__text">両頬/フェイスライン</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">-</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥27,500</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥74,250</p>
                          </div>
                        </div>
                      </div>
                      <div className="c-table__body">
                        <div className="c-table__body__row" key={v4()}>
                          <div className="c-table__body__item">
                            <p className="c-table__text">首/下顎</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">-</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥22,000</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥59,400</p>
                          </div>
                        </div>
                      </div>
                      <div className="c-table__body">
                        <div className="c-table__body__row" key={v4()}>
                          <div className="c-table__body__item">
                            <p className="c-table__text">
                              額/上眼瞼/下眼瞼（各）
                            </p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">-</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥11,000</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥29,700</p>
                          </div>
                        </div>
                      </div>
                      <div className="c-table__body">
                        <div className="c-table__body__row" key={v4()}>
                          <div className="c-table__body__item">
                            <p className="c-table__text">
                              アイシャワー【額、眼周囲】
                            </p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">-</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥22,000</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥59,400</p>
                          </div>
                        </div>
                      </div>
                      <div className="c-table__body">
                        <div className="c-table__body__row" key={v4()}>
                          <div className="c-table__body__item">
                            <p className="c-table__text">
                              HIFUシャワー【顔全体】
                            </p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">-</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥26,400</p>
                          </div>
                          <div className="c-table__body__item">
                            <p className="c-table__text">¥71,280</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== HIFU【リフトアップ・たるみ・しわ・小じわ】1 ========== */}

            {/* =========== HIFU ハイパーリフト【リフトアップ・たるみ・しわ・小じわ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_hifu_03"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    HIFU ハイパーリフト【リフトアップ・たるみ・しわ・小じわ】
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      High Intensity Focused
                      Ultrasoundの略称で、「高密度焦点式超音波」という意味です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      一転に集中させた超音波を真皮・SMAS層・皮下組織や脂肪層に照射することで、皮膚の表面を火傷させることなく熱で引き締める治療法で、皮膚表面にはダメージを与えることなく、顔のたるみを引き締めることができる新しいリフトアップ治療機器です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      一時的な赤み、熱感、火傷、色素沈着、疼痛
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※光線に過敏方又はアレルギーの方、光過敏の副作用があるお薬を内服されている方は施術をお断りさせていただく場合がございます。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/HIFU-18c942f975224a51960b87123fe2496b"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">HIFU初回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">HIFU2回目以降料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体トライアル※１</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥89,100</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥108,900</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体　※２</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥257,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥277,200</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１）顔全体トライアル…HIFU＋Sublime＋Sublime
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）顔全体…HIFU＋Sublime＋Sublime＋HIFU＋Sublime＋Sublime＋HIFU
                </p>
              </div>
            </section>
            {/* =========== HIFU ハイパーリフト【リフトアップ・たるみ・しわ・小じわ】 ========== */}

            {/* =========== 毛穴・リフトアップエイジング　※１ ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_pore_lift_up_aging"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    毛穴・リフトアップエイジング　※１
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            顔全体
                            <br />
                            （初回の方）※２
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥69,300</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥187,110</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥93,060</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥251,260</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１） 1ヶ月目：HIFU＋PQAge　2ヶ月目：SR　の施術になります
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）HIFU　または　PQAge　が初回の方
                </p>
              </div>
            </section>
            {/* =========== 毛穴・リフトアップエイジング　※１ ========== */}

            {/* =========== ハイドラフェイシャル【毛穴詰まり、にきび】1 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_hydra_facial_01"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ハイドラフェイシャル【毛穴詰まり、にきび】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">6回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">12回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥24,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥126,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥220,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">上腕</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥24,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥65,340</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">背中（上）</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥40,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥109,890</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">セット※</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥82,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥222,750</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※セット施術部位…顔全体＋上腕＋背中（上）
                </p>
              </div>
            </section>
            {/* =========== ハイドラフェイシャル【毛穴詰まり、にきび】1 ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ダーマペン【にきび・ニキビ痕・毛穴】 ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_dermapen"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ダーマペン【にきび・ニキビ痕・毛穴】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体／首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥35,640</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体＋首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥35,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥95,040</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">目の下＋目尻/5cm☓5cm</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥25,080</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== ダーマペン【にきび・ニキビ痕・毛穴】 ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ダーマペンオプション（ダーマペン後導入液） ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_dermapen_option"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ダーマペンオプション（ダーマペン後導入液）
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">導入</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">培養上清液</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">プラセンタ</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥35,640</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">高品質ヒアルロン酸</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">BOTOX(10単位）</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== ダーマペンオプション（ダーマペン後導入液） ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ウルトラアクセント  ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_ultra_accent"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ウルトラアクセント（痩身）※１
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">チケット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">お試し2チケット</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥20,790</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">10チケット</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥93,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">20チケット</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥176,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">30チケット</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥248,600</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">追加10チケット※２</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥83,600</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">追加20チケット※２</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥158,400</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１）こちらは、姉妹院『
                  <a
                    className="u-txt-underline"
                    href="http://tenjin-hbc.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    天神ホリスティックビューティークリニック
                  </a>
                  』『
                  <a
                    className="u-txt-underline"
                    href="http://www.himawari-hakata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    博多皮ふ科
                  </a>
                  』での施術となります。
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）20チケット以上購入の方が追加購入される場合
                </p>
              </div>
            </section> */}
            {/* =========== ウルトラアクセント  ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ウルトラアクセント（痩身）　セット　※１  ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_ultra_accent_perfect_body_set_legs_and_arms_set"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ウルトラアクセント（痩身）　セット　※１
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">メニュー名</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">6回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            パーフェクトボディ（美脚・美腕)セット※２
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥74,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥188,100</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥374,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            パーフェクトボディセット※３
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥37,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥99,770</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥187,110</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１）こちらは、姉妹院『
                  <a
                    className="u-txt-underline"
                    href="http://tenjin-hbc.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    天神ホリスティックビューティークリニック
                  </a>
                  』『
                  <a
                    className="u-txt-underline"
                    href="http://www.himawari-hakata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    博多皮ふ科
                  </a>
                  』での施術となります。
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）施術部位…二の腕、太もも、膝上、ふくらはぎ
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※３）施術部位…上腹部、下腹部、脇腹、背中
                </p>
              </div>
            </section> */}
            {/* =========== ウルトラアクセント（痩身）　セット　※１  ========== */}

            {/* =========== Vシェイプ（小顔）※１  ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_v_shape"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vシェイプ（小顔）※１
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">6回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥110,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥198,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">あご＋首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥33,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥88,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥176,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">フェイシャルセット※２</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥69,300</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥184,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥346,500</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１）こちらは、姉妹院『
                  <a
                    className="u-txt-underline"
                    href="http://tenjin-hbc.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    天神ホリスティックビューティークリニック
                  </a>
                  』『
                  <a
                    className="u-txt-underline"
                    href="http://www.himawari-hakata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    博多皮ふ科
                  </a>
                  』での施術となります。
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）ウルトラアクセント＋Vシェイプ　セット、施術部位…全顔、あご、デコルテ
                </p>
              </div>
            </section>
            {/* =========== Vシェイプ（小顔）※１  ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ウルトラアクセント＋Vシェイプ フェイシャルセット  ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_ultra_accent_v_shape_facial_set"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ウルトラアクセント＋Vシェイプ フェイシャルセット
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">6回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">全顔、あご、デコルテ</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥63,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥168,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥315,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※こちらは、姉妹院『
                  <a
                    className="u-txt-underline"
                    href="http://tenjin-hbc.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    天神ホリスティックビューティークリニック
                  </a>
                  』『
                  <a
                    className="u-txt-underline"
                    href="http://www.himawari-hakata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    博多皮ふ科
                  </a>
                  』での施術となります。
                </p>
              </div>
            </section> */}
            {/* =========== ウルトラアクセント＋Vシェイプ フェイシャルセット  ========== */}

            {/* =========== Qスイッチルビーレーザー ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_q_switch_ruby_laser"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Qスイッチルビーレーザー
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥37,900</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== Qスイッチルビーレーザー ========== */}

            {/* =========== ニキビ集中コース レーザーフェイシャル：3回(4週間に1度のペース) ケミカルピーリング：6回(2週間に1度のペース) ビタミンCイオン導入：6回(2週間に1度のペース)  ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_pimple_intensive_course"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ニキビ集中コース
                  </h2>
                </div>

                <div className="c-column2">
                  <div className="c-column2__item">
                    <p className="c-text">
                      レーザーフェイシャル：3回(4週間に1度のペース)
                    </p>
                    <p className="c-text">
                      ケミカルピーリング：6回(2週間に1度のペース)
                    </p>
                    <p className="c-text">
                      ビタミンCイオン導入：6回(2週間に1度のペース)
                    </p>
                  </div>
                  <div className="c-column2__item">
                    <div className="c-table -col1 u-mt00">
                      <div className="c-table__inner">
                        <div className="c-table__head">
                          <div className="c-table__head__item u-w100p">
                            <p className="c-table__text">1回料金</p>
                          </div>
                        </div>
                        <div className="c-table__body">
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item u-w100p">
                              <p className="c-table__text">¥77,000</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== ニキビ集中コース レーザーフェイシャル：3回(4週間に1度のペース) ケミカルピーリング：6回(2週間に1度のペース) ビタミンCイオン導入：6回(2週間に1度のペース)  ========== */}

            {/* =========== レーザートーニング【肝斑・くすみ・毛穴】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_laser_toning"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    レーザートーニング【肝斑・くすみ・毛穴】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">10回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥39,600</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥74,800</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔(部分)</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥6,600</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥56,100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== レーザートーニング【肝斑・くすみ・毛穴】 ========== */}

            {/* =========== Picoフラクショナル（マイルド）【毛穴開き・キメ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_pico_fractional_mild"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Picoフラクショナル（マイルド）【毛穴開き・キメ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥4,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥12,540</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬＋鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥7,480</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥21,320</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Picoフラクショナル（マイルド）【毛穴開き・キメ】 ========== */}

            {/* =========== Picoフラクショナル（ストロング）【毛穴開き・キメ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_pico_fractional_strong"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Picoフラクショナル（ストロング）【毛穴開き・キメ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥25,080</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬＋鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥14,850</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥40,100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Picoフラクショナル（ストロング）【毛穴開き・キメ】 ========== */}

            {/* =========== 毛穴撃退セット※ ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_pico_fractional_strong"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    毛穴撃退セット※
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥31,680</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥85,540</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,910</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥25,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬＋鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥14,650</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥39,560</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※カーボンピーリング＋Picoフラクショナル（マイルド）
                </p>
              </div>
            </section>
            {/* =========== 毛穴撃退セット※ ========== */}

            {/* =========== 水光注射 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_water_light_injection_acne_treatment"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">水光注射</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      美容先進国の韓国では、煌めくようなツヤと弾力あるハリを持つお肌のことを「水光皮膚」と呼び、その治療方法としてソフトヒアルロン酸を注入する施術が水光注射と言われています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      従来ソフトヒアルロン酸の注入は手打ちによる施術が主な方法ですが、注入量を均一に保てない、注入速度や圧力が一定でないため施術後の皮膚が凸凹したり膨疹となるなどの問題を解決するために針の長さや注入量、皮膚の吸引圧を設定することが可能な専用の機器で、均一な皮膚表層にまんべんなく注入することが可能になっています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      さまざまな薬剤を用いて皮膚の水分保持、小じわの改善、皮膚の質感の向上を目的とした施術です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      注射箇所の赤み・発疹、かゆみ、内出血
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/145f93952d094afa9bdb62e4312ab9b9"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">メニュー</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            にきび治療【ビタミンC】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,550</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            にきび痕治療【成長因子】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥15,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥41,580</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">肝斑治療</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,550</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            肝斑治療【レーザートーニングと併用の方】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            ふっくら肌【ヒアルロン酸】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥80,190</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">大理石肌【Botx】</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥148,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">PRP【1cc】</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,880</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 水光注射 ========== */}

            {/* =========== Qルビーレーザー シミ ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_q_ruby_laser_stain"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Qルビーレーザー シミ
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      メラニン色素に作用するテーザーを照射し、シミやそばかすなどの色素性疾患を改善する治療法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      皮膚疾患治療のレーザー機として最もスタンダードで実績を残しており、出力を調整することで、一人ひとりの肌や症状に応じて最適な治療が可能です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※施術部位は軟膏を塗布しテープ保護を行います。
                      <br />
                      ※痛みが強い場合は麻酔をご利用いただくことも可能です。
                      <br />
                      ※2週後、6週後の経過観察の為、通院が必要です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      軽いやけど、色素残存、色素沈着、脱色素・白斑化
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/Q-d9cb9392a9184e0781a52b5d17e7f292"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">直径0.6cmまで</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,300</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">0.1cmUPにつき</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">+ ¥550</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Qルビーレーザー シミ ========== */}

            {/* =========== Qルビーレーザー いれずみ除去 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_irisumi_removal"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Qルビーレーザー いれずみ除去
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      メラニン色素に作用するテーザーを照射し、シミやそばかすなどの色素性疾患を改善する治療法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      皮膚疾患治療のレーザー機として最もスタンダードで実績を残しており、出力を調整することで、一人ひとりの肌や症状に応じて最適な治療が可能です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※施術部位は軟膏を塗布しテープ保護を行います。
                      <br />
                      ※痛みが強い場合は麻酔をご利用いただくことも可能です。
                      <br />
                      ※2週後、6週後の経過観察の為、通院が必要です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      軽いやけど、色素残存、色素沈着、脱色素・白斑化
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/Q-d9cb9392a9184e0781a52b5d17e7f292"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">10mm×10mm程度</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥9,350</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">20mm×20mm程度</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">50mm×50mm程度</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥33,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">100mm×100mm程度</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥77,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Qルビーレーザー いれずみ除去 ========== */}

            {/* =========== PICOレーザー ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_pico_laser"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">PICOレーザー</h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">直径1cmまで</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">0.1cmUPにつき</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">+ ¥1100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== PICOレーザー ========== */}

            {/* =========== CO2レーザー ほくろ除去 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_co2_laser"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    CO2レーザー ほくろ除去
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      水に吸収されやすい性質を持っているCO2レーザーを用いて、皮膚表面を瞬間的に蒸発させ、イボやほくろを焼いて消失させる治療法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※施術部位は軟膏を塗布しテープ保護を行います。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※翌日の付け替え、1週間後・2週間後の経過観察の為、通院が必要です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      施術箇所の赤み・腫れ、ほくろ再発、傷跡の肥厚性瘢痕、ケロイド、窪みが残る
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/CO2-4b288ae6a3594f778a5ed083b83a907c"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">3mm以下</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,770</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">0.3mm以上5mm未満</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥17,710</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">5mm</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥23,540</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== CO2レーザー ほくろ除去 ========== */}

            {/* =========== フラクショナルレーザー【にきび跡・クレーター】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_fractional_laser"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    フラクショナルレーザー【にきび跡・クレーター】
                  </h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">メニュー</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥77,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥207,900</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥327,250</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥187,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥93,500</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== フラクショナルレーザー【にきび跡・クレーター】 ========== */}

            {/* =========== 高濃度ビタミンC点滴25g ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_high_concentration_vitamin_C_25"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    高濃度ビタミンC点滴25g
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ビタミンCはコラーゲンの生成促進、メラニン生成の抑制、抗酸化作用、疲労回復・抗ストレス効果、免疫力向上、がん予防作用、生活習慣病改善、抗アレルギー作用、がん予防効果が期待されています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ビタミンCの血漿濃度は、およそ
                      400mg/日で飽和すると報告されていますが、ビタミンCは水溶性のため摂取量を増やしても余剰分は尿として排出され、大量に経口摂取しても血中濃度はある一定以上から上がりません。
                    </span>
                    <span className="c-doc-l-text__cell">
                      高濃度ビタミンC点滴により、血液中の濃度を急激に上げることで、経口摂取の数十倍の量が血管内に行き渡り、血中濃度を高めることにより、ビタミンCを必要とする各器官に直接行き渡らせることができます。
                    </span>
                    <span className="c-doc-l-text__cell">
                      そのため、経口摂取とは異なるレベルで上記の作用が期待できます。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      点滴時の血管痛、口渇（のどの渇き）、低血糖（めまい、冷や汗、疲労感等）、注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷、⾎管迷⾛神経反応、血管痛 等
                    </span>

                    <span className="c-doc-l-text__cell">
                      ※G6PD欠損症の方、腎不全があり透析中の方、透析の必要性が高い方、心不全や不整脈の方、浮腫・腹水のある方、ビタミンCでアレルギーが出たことのある方・または過敏症の方は施術を受けていただくことができません。
                    </span>

                    <span className="c-doc-l-text__cell">
                      ※初回、G6PD検査を行わせていただく場合がございます。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/C-25g-409106ad041d421aa528ab0f5dba27a7"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 高濃度ビタミンC点滴25g ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== シンデレラ点滴＋注射 ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_high_concentration_vitamin_C_25"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    シンデレラ点滴＋注射
                  </h2>
                </div>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥4,950</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== シンデレラ点滴＋注射 ========== */}

            {/* =========== 白玉点滴 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_high_concentration_vitamin_C_25"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">白玉点滴</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      白玉点滴とは、グルタチオンを主成分として配合した美容点滴です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      メラニン色素の抑制によるシミ予防と改善、美白、アンチエイジング効果、疲労・倦怠感の改善、肝機能向上、解毒、抗アレルギー作用、免疫高効果が期待されます。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷、⾎管迷⾛神経反応、血管痛 等
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/73d072c3a768448094a961bf5a5f48fe"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">レギュラー</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">高濃度</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥5,280</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,580</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 白玉点滴 ========== */}

            {/* =========== 血液クレンジング ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_high_concentration_vitamin_C_25"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    血液クレンジング
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      100～200ccほどの少量の血液を採取し、医療用オゾンガスを投与して血液とオゾンを反応させることで、活性化した血液を再び身体の中に戻すという治療法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      細胞が活性化することで冷え性、肩こり、頭痛、美肌効果、認知症、脳血管障害などの予防や抗酸化作用、老化予防、体力・免疫力向上、代謝アップが期待できます。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      一時的なだるさ、クエン酸ナトリウム又はヘパリンナトリウムの使用によるショック
                      アナフィラキシー、血小板減少、HIT等に伴い血小板減少、血栓症、痺れ
                      注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着
                      感染症、神経損傷、⾎管迷⾛神経反応 等
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/ae21f447e1224c669d309470d720071e"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,200</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 血液クレンジング ========== */}

            {/* =========== にんにく注射 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_high_concentration_vitamin_C_25"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">にんにく注射</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      アリナミン(ビタミンB1)を主成分として配合している液体を注射器を使って静脈に投与する施術です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      疲労回復、二日酔いの回復、免疫力UP、肩こり・腰痛の改善効果が期待できます。
                    </span>
                    <span className="c-doc-l-text__cell">
                      アリナミン（ビタミンB1）はにんにくの成分であり、注射中にんにくのような臭いを感じるため、にんにく注射と呼ばれています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      治療回数は、成人に対し1日1回10mlを週1回が目安です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      悪心、嘔吐、舌炎、頻尿、頭痛、下痢、掻痒感、蕁麻疹、注射部位及び周囲の疼痛
                      内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷
                      ⾎管迷⾛神経反応 等
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/da6053f661034e0d9b6fe64c7e238ab4"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1A</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥1,100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== にんにく注射 ========== */}

            {/* =========== プラセンタ注射 ラエンネック ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_placenta_injection_laennec"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    プラセンタ注射 ラエンネック
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ヒト胎盤由来成分（胎盤酵素分解物の水溶性物質）とpH調整剤が含まれたラエンネックを皮下又は筋肉内に注射することで慢性肝疾患における肝機能の改善が期待されます。
                    </span>
                    <span className="c-doc-l-text__cell">
                      その他薬効・薬理として、肝再生促進作用、肝細胞DNA合成促進作用、実験的肝障害抑制作用、抗脂肝作用、肝繊維増殖の抑制作用があります。
                    </span>
                    <span className="c-doc-l-text__cell">
                      治療回数は、成人に対し1日1回2ml～10mlを毎日又は隔日が目安です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ヒト胎盤を原材料としていることに由来する感染症伝播のリスクを完全に排除することはできません。
                    </span>
                  </p>

                  <div className="c-doc-table -l">
                    <div className="c-doc-table__inner">
                      <div className="c-doc-table__cell">
                        <p className="c-doc-table__cell__title">
                          副作用
                          <span className="c-doc-table__cell__adjust-text">
                            :
                          </span>
                        </p>
                        <p className="c-doc-table__cell__text">
                          注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷、⾎管迷⾛神経反応、頭痛、肝機能障害（AST、ALTの上昇）等
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/32f43ff01e8d41709fb8f6fa1d4031a2"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">ブラン</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">1A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥1,100</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">2A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥1,980</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">3A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥2,970</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">10A＋P１A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥10,890</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">20A＋P3A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥19,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== プラセンタ注射 ラエンネック ========== */}

            {/* =========== プラセンタ注射 メルスモン ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_placenta_injection_mersmon"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    プラセンタ注射 メルスモン
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ヒト胎盤由来成分（胎盤絨毛分解の水溶性物質）とベンジルアルコールが含まれたメルスモンを皮下又は筋肉内に注射することで更年期障害、乳汁分泌不全が期待できます。
                    </span>
                    <span className="c-doc-l-text__cell">
                      その他薬効・薬理として、組織呼吸促進作用、創傷治癒促進作用、抗疲労作用、硝子体及び球血膜下出血の吸収促進作用があります。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※まだ充分明らかではない、メルスモンの作用機序は上記のような諸作用が認められておりこれらの多様な生物学的活性作用が広凡な生体過程への賦活作用を示し、組織細胞の新陳代謝を高め、身体の以上状態を正常化するものと推測されています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      治療回数は成人に対し1日1回2ml～10mlを毎日又は隔日が目安となります。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ヒト胎盤を原材料としていることに由来する感染症伝播のリスクを完全に排除することはできません。
                    </span>
                  </p>

                  <div className="c-doc-table -l">
                    <div className="c-doc-table__inner">
                      <div className="c-doc-table__cell">
                        <p className="c-doc-table__cell__title">
                          副作用
                          <span className="c-doc-table__cell__adjust-text">
                            :
                          </span>
                        </p>
                        <p className="c-doc-table__cell__text">
                          注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷、⾎管迷⾛神経反応 等
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/a3125e850a714c029dfe79aa6b16dc04"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">ブラン</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">1A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥1,320</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">2A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥2,200</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">3A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,410</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">10A＋P１A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥12,100</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">20A＋P3A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥24,200</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== プラセンタ注射 メルスモン ========== */}

            {/* =========== ボトックス ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_botox"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">ボトックス</h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">【A】より１ヶ所</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥26,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">【A】より３ヶ所</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">３ヶ所＋１ヶ所※１</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥14,850</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">エラ縮小</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥63,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  【A】眉間、額、目尻、バニーズライン｟鼻のシワ｠、マリオネットライン｟口角下｠、オトガイ｟アゴ梅干し｠より１ヶ所
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※１）３ヶ所施術されるの方で、さらに１ヶ所追加される方
                </p>
              </div>
            </section>
            {/* =========== ボトックス ========== */}

            {/* =========== ボトックス【多汗症】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_botox_first"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ボトックス【多汗症】
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">初回</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両脇</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥60,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== ボトックス【多汗症】 ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== HARG療法  ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_harg_therapy_trial"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">HARG療法</h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">トライアル</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">前頭部</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥99,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥297,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥528,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">全頭部</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥121,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥121,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥363,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥660,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== HARG療法  ========== */}

            {/* =========== ﾏｲﾔｰｽﾞ・ｶｸﾃﾙ点滴 ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="female_royal_acrylic_infusion"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ﾏｲﾔｰｽﾞ・ｶｸﾃﾙ点滴
                  </h2>
                </div>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥7,500</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== ﾏｲﾔｰｽﾞ・ｶｸﾃﾙ点滴 ========== */}

            {/* =========== ピアス ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_piercing_medical_standard"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">ピアス</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ピアッシング専用器具（ピアスガン）を用いて耳たぶへピアスの穴あけをする医療行為です。
                      金属アレルギーの方でもアレルギー対応素材を使用したファーストピアスもご用意しています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※感染症リスクを下げるため抗生物質を3日分処方します。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      鈍痛、化膿、感染症、ピアスの埋没、表皮嚢腫、血管迷走神経反射
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※金属アレルギーの方はお申し出ください。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/7fdba0e394ad4d81bb6bdcb8fa3d1405"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">デザイン</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">両耳</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">片耳</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">ステンレス</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,850</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,080</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">チタン</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥4,950</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,960</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">透明</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥4,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,520</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※抗生物質(ｾﾌｿﾞﾝ）別途￥770必要となります
                </p>
              </div>
            </section>
            {/* =========== ピアス ========== */}

            {/* =========== ミラドライ ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_mira_dry"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">ミラドライ</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ミラドライは切らないわき汗治療器として皮膚を切ることなくわきが・多汗症治療を行える機械です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      マイクロ（電磁）波を患部にあてて、ワキガの原因となる汗腺をターゲットとし、アポクリン汗腺・エクリン腺にダメージを与える治療方法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ダメージを受けた汗腺は発汗機能が停止するため、長期間の効果の実感が期待できます。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      浮腫み、痛み、色素沈着、硬化、感覚の変化、発赤、熱傷、一時的な治療部位（腕）やその周辺の筋肉及び指の力減少、一時的なあざ、患部の突っ張り感
                    </span>
                    <span className="c-doc-l-text__cell">
                      運動はしばらく控えて下さい。
                      入浴は翌日から、シャワーは当日から可能です。
                      ただし、治療部位を過剰に濡らしたり温めるのはお控えください。
                      痛みや腫れを緩和するために治療後2-3日は、定期的にアイスパックを脇の下にはさんで冷やして下さい。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/6f57634bc44844e193c8c1ea027ab91c"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">1パス</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥356,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">2パス</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥407,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            年間保証あり／２回まで施術可能
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥440,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== ミラドライ ========== */}

            {/* =========== PRP 血小板療法1cc ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_prp_platelet_therapy_1cc"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    PRP 血小板療法1cc
                  </h2>
                </div>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥66,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== PRP 血小板療法1cc ========== */}

            {/* =========== わきのくすみ・色素沈着（黒ずみ）治療 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_piercing_medical_standard"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    わきのくすみ・色素沈着（黒ずみ）治療
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">メニュー名</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">10回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">ピーリング</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥6,050</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">レーザートーニング</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥38,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥77,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">ビタミンCイオン導入</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥2,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            トラネキサム酸イオン導入
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,850</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            イオン導入セット（ビタミンC・トラネキサム酸　５回ずつ）
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== わきのくすみ・色素沈着（黒ずみ）治療 ========== */}
          </div>
        </TabPanel>

        <TabPanel>
          <div data-tab-target="#tab2">
            {/* =========== レーザーフェイシャル【しみ・そばかす・にきび治療】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_first_laser_facial"
            >
              <div className="l-main-content-hero__inner">
                <p className="c-text u-mb30">
                  価格は全て税込価格で表示しています
                </p>
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    レーザーフェイシャル【しみ・そばかす・にきび治療】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,550</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== レーザーフェイシャル【しみ・そばかす・にきび治療】 ========== */}

            {/* =========== レーザーフェイシャル＋ケミカルピーリング【にきび・にきび痕】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_laser_facial_and_chemical_peeling"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    レーザーフェイシャル＋ケミカルピーリング【にきび・にきび痕】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥21,780</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥58,810</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔(部分)</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥18,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥50,490</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== レーザーフェイシャル＋ケミカルケミカルピーリング【にきび・にきび痕】 ========== */}

            {/* =========== Vビーム＋ケミカルピーリング【にきび痕・赤にきび】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_v_beam_and_chemical_peeling"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vビーム＋ケミカルピーリング【にきび痕・赤にきび】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥34,100</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥92,070</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥20,900</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥56,430</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Vビーム＋ケミカルピーリング【にきび痕・赤にきび】 ========== */}

            {/* =========== Vビーム にきび ・にきび痕 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_v_beam"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vビーム にきび ・にきび痕
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>

                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Vビーム にきび ・にきび痕 ========== */}

            {/* =========== Vビーム 赤ら顔 ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_v_beam_red_face"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vビーム 赤ら顔
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔(部分)</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥10,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== Vビーム 赤ら顔 ========== */}

            {/* =========== Vビーム 血管拡張 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_v_beam_vasodilation"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vビーム 血管拡張
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">小鼻〜鼻下</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥5,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                      </div>

                      {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
                      {/* <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">下肢</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥20,000〜</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Vビーム 血管拡張 ========== */}

            {/* =========== 男性 ケミカルピーリング 【にきび・にきび痕・黒ずみ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_chemical_peeling"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ケミカルピーリング 【にきび・にきび痕・黒ずみ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥7,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥21,950</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">背中半分</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,550</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥31,190</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両ワキ</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥6,050</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥17,240</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 男性 ケミカルピーリング 【にきび・にきび痕・黒ずみ】 ========== */}

            {/* =========== カーボンピーリング 【毛穴詰まり・毛穴】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_carbon_peeling_01"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    カーボンピーリング 【毛穴詰まり・毛穴】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥56,100</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥5,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥15,680</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬＋鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥25,080</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== カーボンピーリング 【毛穴詰まり・毛穴】 ========== */}

            {/* =========== PQAge evolution plus【にきび・リフトアップ・小じわ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_p_q_age_evolution_plus"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    PQAge evolution plus【にきび・リフトアップ・小じわ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">　</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">初回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体/デコルテ</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥15,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥56,980</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">首/手の甲</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,580</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥12,100</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,550</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体＋首/背中上部</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥24,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥89,430</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">ボディ(15×20cm）</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,090</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥18,260</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥67,540</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== PQAge evolution plus【にきび・リフトアップ・小じわ】 ========== */}

            {/* =========== ビタミンCイオン導入【にきび・炎症・美白】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_vitamin_c_ion"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ビタミンCイオン導入【にきび・炎症・美白】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥2,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥6,270</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">背中半分</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,300</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥9,410</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== ビタミンCイオン導入【にきび・炎症・美白】 ========== */}

            {/* =========== トラネキサム酸イオン導入【肝斑・にきび痕・くすみ・美白】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_tranexamic_acid_ion"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    トラネキサム酸イオン導入【肝斑・にきび痕・くすみ・美白】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,850</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥10,980</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== トラネキサム酸イオン導入【肝斑・にきび痕・くすみ・美白】 ========== */}

            {/* =========== イオン導入セット【ビタミンCイオン導入＋トラネキサム酸イオン導入】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_Iontophoresis_set"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    イオン導入セット【ビタミンCイオン導入＋トラネキサム酸イオン導入】
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥27,230</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== イオン導入セット【ビタミンCイオン導入＋トラネキサム酸イオン導入】 ========== */}

            {/* =========== E-plus SR ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_e_plus_sr"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus SR【しみ・そばかす・赤ら顔】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">頬のみ</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus SR ========== */}

            {/* =========== E-plus Sublime/ST【たるみ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_e_plus_sublime"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus Sublime/ST【たるみ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥27,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥74,250</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus Sublime/ST【たるみ】 ========== */}

            {/* =========== E-plus IR【深いしわ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_e_plus_ir"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus IR【深いしわ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">しわ1部位</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">しわ3部位</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus IR【深いしわ】 ========== */}

            {/* =========== E-plus sublime＋ST【小じわ・毛穴】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_e_plus_motif"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus sublime＋ST【小じわ・毛穴】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">頬〜顎下</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus sublime＋ST【小じわ・毛穴】 ========== */}

            {/* =========== 年齢毛穴セット【E-plus sublime+motif】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_e_plus_sublime_motif"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    年齢毛穴セット【E-plus sublime+motif】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 年齢毛穴セット【E-plus sublime+motif】 ========== */}

            {/* =========== アンチエイジングセット【E-plus Sublime+IR】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_anti_aging_set"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    アンチエイジングセット【E-plus Sublime+IR】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔+しわ3部位</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== アンチエイジングセット【E-plus Sublime+IR】 ========== */}

            {/* =========== E-plus Sublime+IR ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_e_plus_sublime_ir"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus Sublime+IR
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔+しわ3部位</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus Sublime+IR ========== */}

            {/* =========== E-plus Triniti(SR+Sublime+motifモード) ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_e_plus_trinity_plus"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    E-plus Triniti(SR+Sublime+motifモード)
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥60,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥163,350</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥257,130</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">SR頬のみ＋他顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥52,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥142,560</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥224,400</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== E-plus Triniti(SR+Sublime+motifモード) ========== */}

            {/* =========== イントラジェン【たるみ・小じわ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_Intragen"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    イントラジェン【たるみ・小じわ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥148,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体＋首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥71,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥193,050</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== イントラジェン【たるみ・小じわ】 ========== */}

            {/* =========== HIFU【リフトアップ・たるみ・しわ・小じわ】1 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_hifu_01"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    HIFU【リフトアップ・たるみ・しわ・小じわ】
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      High Intensity Focused
                      Ultrasoundの略称で、「高密度焦点式超音波」という意味です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      一転に集中させた超音波を真皮・SMAS層・皮下組織や脂肪層に照射することで、皮膚の表面を火傷させることなく熱で引き締める治療法で、皮膚表面にはダメージを与えることなく、顔のたるみを引き締めることができる新しいリフトアップ治療機器です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      一時的な赤み、熱感、火傷、色素沈着、疼痛
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※光線に過敏方又はアレルギーの方、光過敏の副作用があるお薬を内服されている方は施術をお断りさせていただく場合がございます。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/HIFU-18c942f975224a51960b87123fe2496b"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">初回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥66,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥178,200</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬/フェイスライン</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥27,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥74,250</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">首/下顎</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            額/上眼瞼/下眼瞼（各）
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            アイシャワー【額、眼周囲】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            HIFUシャワー【顔全体】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥26,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥71,280</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== HIFU【リフトアップ・たるみ・しわ・小じわ】1 ========== */}

            {/* =========== HIFU【リフトアップ・たるみ・しわ・小じわ】3 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_hifu_03"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    HIFU【リフトアップ・たるみ・しわ・小じわ】
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      High Intensity Focused
                      Ultrasoundの略称で、「高密度焦点式超音波」という意味です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      一転に集中させた超音波を真皮・SMAS層・皮下組織や脂肪層に照射することで、皮膚の表面を火傷させることなく熱で引き締める治療法で、皮膚表面にはダメージを与えることなく、顔のたるみを引き締めることができる新しいリフトアップ治療機器です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      一時的な赤み、熱感、火傷、色素沈着、疼痛
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※光線に過敏方又はアレルギーの方、光過敏の副作用があるお薬を内服されている方は施術をお断りさせていただく場合がございます。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/HIFU-18c942f975224a51960b87123fe2496b"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">HIFU初回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">HIFU2回目以降</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体トライアル※１</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥89,100</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥108,900</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体　※２</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥257,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥277,200</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１）顔全体トライアル…HIFU＋Sublime＋Sublime
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）顔全体…HIFU＋Sublime＋Sublime＋HIFU＋Sublime＋Sublime＋HIFU
                </p>
              </div>
            </section>
            {/* =========== HIFU【リフトアップ・たるみ・しわ・小じわ】3 ========== */}

            {/* =========== 毛穴・リフトアップエイジング　※１ ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_pore_lift_up_aging"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    毛穴・リフトアップエイジング　※１
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体（初回の方）※２</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥69,300</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥187,110</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥93,060</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥251,260</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１） 1ヶ月目：HIFU＋PQAge　2ヶ月目：SR　の施術になります
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）HIFU　または　PQAge　が初回の方
                </p>
              </div>
            </section>
            {/* =========== 毛穴・リフトアップエイジング　※１ ========== */}

            {/* =========== ハイドラフェイシャル【毛穴詰まり、にきび】========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_hydra_facial_01"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ハイドラフェイシャル【毛穴詰まり、にきび】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">6回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">12回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥24,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥126,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥220,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">上腕</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥24,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥65,340</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">背中（上）</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥40,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥109,890</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">セット※</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥82,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥222,750</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※セット施術部位…顔全体＋上腕＋背中（上）
                </p>
              </div>
            </section>
            {/* =========== ハイドラフェイシャル【毛穴詰まり、にきび】 ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ダーマペン【にきび・ニキビ痕・毛穴】========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_dermapen"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ダーマペン【にきび・ニキビ痕・毛穴】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体/首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥35,640</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体＋首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥35,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥95,040</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">目の下＋目尻/5cm☓5cm</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥25,080</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== ダーマペン【にきび・ニキビ痕・毛穴】 ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ダーマペンオプション（ダーマペン後導入液）========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_dermapen_option"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ダーマペンオプション（ダーマペン後導入液）
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">導入</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">培養上清液</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">プラセンタ</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥35,640</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">高品質ヒアルロン酸</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">BOTOX(10単位）</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== ダーマペンオプション（ダーマペン後導入液） ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ウルトラアクセント（痩身）※１ ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_ultra_accent_trial"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ウルトラアクセント（痩身）※１
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">チケット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">お試し2チケット</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥20,790</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">10チケット</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥93,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">20チケット</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥176,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">30チケット</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥248,600</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">追加10チケット※２</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥83,600</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">追加20チケット※２</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥158,400</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１）こちらは、姉妹院『
                  <a
                    className="u-txt-underline"
                    href="http://tenjin-hbc.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    天神ホリスティックビューティークリニック
                  </a>
                  』『
                  <a
                    className="u-txt-underline"
                    href="http://www.himawari-hakata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    博多皮ふ科
                  </a>
                  』での施術となります。
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）20チケット以上購入の方が追加購入される場合
                </p>
              </div>
            </section> */}
            {/* =========== ウルトラアクセント（痩身）※１ ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ウルトラアクセント（痩身）　セット　※１  ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_ultra_accent_perfect_body_beautiful_legs_arms_sets"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ウルトラアクセント（痩身）　セット　※１
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">メニュー名</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">6回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            パーフェクトボディ（美脚・美腕)セット ※２
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥74,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥188,100</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥374,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            パーフェクトボディセット ※３
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥37,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥99,770</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥187,110</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１）こちらは、姉妹院『
                  <a
                    className="u-txt-underline"
                    href="http://tenjin-hbc.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    天神ホリスティックビューティークリニック
                  </a>
                  』『
                  <a
                    className="u-txt-underline"
                    href="http://www.himawari-hakata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    博多皮ふ科
                  </a>
                  』での施術となります。
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）施術部位…二の腕、太もも、膝上、ふくらはぎ
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※３）施術部位…上腹部、下腹部、脇腹、背中
                </p>
              </div>
            </section> */}
            {/* =========== ウルトラアクセント（痩身）　セット　※１  ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== ウルトラアクセント パーフェクトボディセット  ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_ultra_accent_perfect_body"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ウルトラアクセント パーフェクトボディセット
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">6回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            上腹部、下腹部、脇腹、背中
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥34,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥90,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥170,100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※こちらは、姉妹院『
                  <a
                    className="u-txt-underline"
                    href="http://tenjin-hbc.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    天神ホリスティックビューティークリニック
                  </a>
                  』『
                  <a
                    className="u-txt-underline"
                    href="http://www.himawari-hakata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    博多皮ふ科
                  </a>
                  』での施術となります。
                </p>
              </div>
            </section> */}
            {/* =========== ウルトラアクセント パーフェクトボディセット  ========== */}

            {/* =========== Vシェイプ（小顔）※１  ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_v_shape"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Vシェイプ（小顔）※１
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">6回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥110,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥198,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">あご＋首</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥33,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥88,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥176,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">フェイシャルセット※２</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥69,300</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥184,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥346,500</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※１）こちらは、姉妹院『
                  <a
                    className="u-txt-underline"
                    href="http://tenjin-hbc.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    天神ホリスティックビューティークリニック
                  </a>
                  』『
                  <a
                    className="u-txt-underline"
                    href="http://www.himawari-hakata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    博多皮ふ科
                  </a>
                  』での施術となります。
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※２）ウルトラアクセント＋Vシェイプ　セット、施術部位…全顔、あご、デコルテ
                </p>
              </div>
            </section>
            {/* =========== Vシェイプ（小顔）※１  ========== */}

            {/* =========== Qスイッチルビーレーザー ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_q_switch_ruby_laser"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Qスイッチルビーレーザー
                  </h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥37,900</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== Qスイッチルビーレーザー ========== */}

            {/* =========== ニキビ集中コース レーザーフェイシャル：3回(4週間に1度のペース) ケミカルピーリング：6回(2週間に1度のペース) ビタミンCイオン導入：6回(2週間に1度のペース)  ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_pimple_intensive_course"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ニキビ集中コース
                  </h2>
                </div>

                <div className="c-column2">
                  <div className="c-column2__item">
                    <p className="c-text">
                      レーザーフェイシャル：3回(4週間に1度のペース)
                    </p>
                    <p className="c-text">
                      ケミカルピーリング：6回(2週間に1度のペース)
                    </p>
                    <p className="c-text">
                      ビタミンCイオン導入：6回(2週間に1度のペース)
                    </p>
                  </div>
                  <div className="c-column2__item">
                    <div className="c-table -col1 u-mt00">
                      <div className="c-table__inner">
                        <div className="c-table__head">
                          <div className="c-table__head__item u-w100p">
                            <p className="c-table__text">料金</p>
                          </div>
                        </div>
                        <div className="c-table__body">
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item u-w100p">
                              <p className="c-table__text">¥77,000</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== ニキビ集中コース レーザーフェイシャル：3回(4週間に1度のペース) ケミカルピーリング：6回(2週間に1度のペース) ビタミンCイオン導入：6回(2週間に1度のペース)  ========== */}

            {/* =========== レーザートーニング【肝斑・くすみ・毛穴】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_laser_toning"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    レーザートーニング【肝斑・くすみ・毛穴】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">10回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥39,600</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥74,800</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔（部分）</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥6,600</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥56,100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== レーザートーニング【肝斑・くすみ・毛穴】 ========== */}

            {/* =========== Picoフラクショナル（マイルド）【毛穴開き・キメ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_pico_fractional_mild"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Picoフラクショナル（マイルド）【毛穴開き・キメ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥4,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥12,540</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬＋鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥7,480</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥21,318</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Picoフラクショナル（マイルド）【毛穴開き・キメ】 ========== */}

            {/* =========== Picoフラクショナル（ストロング）【毛穴開き・キメ】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_pico_fractional_strong"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Picoフラクショナル（ストロング）【毛穴開き・キメ】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥25,080</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬＋鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥14,850</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥40,100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Picoフラクショナル（ストロング）【毛穴開き・キメ】 ========== */}

            {/* =========== 毛穴撃退セット※ ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_pore_repelling_set"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    毛穴撃退セット※
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔全体</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥31,680</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥85,536</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,910</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥25,394</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬＋鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥14,652</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥39,560</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※カーボンピーリング＋Picoフラクショナル（マイルド）
                </p>
              </div>
            </section>
            {/* =========== 毛穴撃退セット※ ========== */}

            {/* =========== 水光注射 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_water_light_injection_acne_scar_treatment"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">水光注射</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      美容先進国の韓国では、煌めくようなツヤと弾力あるハリを持つお肌のことを「水光皮膚」と呼び、その治療方法としてソフトヒアルロン酸を注入する施術が水光注射と言われています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      従来ソフトヒアルロン酸の注入は手打ちによる施術が主な方法ですが、注入量を均一に保てない、注入速度や圧力が一定でないため施術後の皮膚が凸凹したり膨疹となるなどの問題を解決するために針の長さや注入量、皮膚の吸引圧を設定することが可能な専用の機器で、均一な皮膚表層にまんべんなく注入することが可能になっています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      さまざまな薬剤を用いて皮膚の水分保持、小じわの改善、皮膚の質感の向上を目的とした施術です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      注射箇所の赤み・発疹、かゆみ、内出血
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/145f93952d094afa9bdb62e4312ab9b9"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">メニュー</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            にきび治療【ビタミンC】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,550</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            にきび痕治療【成長因子】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥15,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥41,580</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">肝斑治療</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,550</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            肝斑治療【レーザートーニングと併用の方】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            ふっくら肌【ヒアルロン酸】
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥80,190</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">大理石肌【Botx】</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥148,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">PRP【1cc】</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,880</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 水光注射 ========== */}

            {/* =========== Qルビーレーザー シミ ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_q_ruby_laser_stain"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Qルビーレーザー シミ
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      メラニン色素に作用するテーザーを照射し、シミやそばかすなどの色素性疾患を改善する治療法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      皮膚疾患治療のレーザー機として最もスタンダードで実績を残しており、出力を調整することで、一人ひとりの肌や症状に応じて最適な治療が可能です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※施術部位は軟膏を塗布しテープ保護を行います。
                      <br />
                      ※痛みが強い場合は麻酔をご利用いただくことも可能です。
                      <br />
                      ※2週後、6週後の経過観察の為、通院が必要です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      軽いやけど、色素残存、色素沈着、脱色素・白斑化
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/Q-d9cb9392a9184e0781a52b5d17e7f292"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">直径0.6cmまで</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,300</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">0.1cmUPにつき</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">+ ¥550</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Qルビーレーザー シミ ========== */}

            {/* =========== Qルビーレーザー いれずみ除去 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_irisumi_removal"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    Qルビーレーザー いれずみ除去
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      メラニン色素に作用するテーザーを照射し、シミやそばかすなどの色素性疾患を改善する治療法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      皮膚疾患治療のレーザー機として最もスタンダードで実績を残しており、出力を調整することで、一人ひとりの肌や症状に応じて最適な治療が可能です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※施術部位は軟膏を塗布しテープ保護を行います。
                      <br />
                      ※痛みが強い場合は麻酔をご利用いただくことも可能です。
                      <br />
                      ※2週後、6週後の経過観察の為、通院が必要です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      軽いやけど、色素残存、色素沈着、脱色素・白斑化
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/Q-d9cb9392a9184e0781a52b5d17e7f292"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">10mm×10mm程度</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥9,350</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">20mm×20mm程度</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥16,500</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">50mm×50mm程度</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥33,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">100mm×100mm程度</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥77,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== Qルビーレーザー いれずみ除去 ========== */}

            {/* =========== PICOレーザー ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_pico_laser"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">PICOレーザー</h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">10㎜×10㎜程度</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">0.1cmUPにつき</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">+ ¥1100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== PICOレーザー ========== */}

            {/* =========== CO2レーザー ほくろ除去 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_co2_laser"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    CO2レーザー ほくろ除去
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      水に吸収されやすい性質を持っているCO2レーザーを用いて、皮膚表面を瞬間的に蒸発させ、イボやほくろを焼いて消失させる治療法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※施術部位は軟膏を塗布しテープ保護を行います。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※翌日の付け替え、1週間後・2週間後の経過観察の為、通院が必要です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      施術箇所の赤み・腫れ、ほくろ再発、傷跡の肥厚性瘢痕、ケロイド、窪みが残る
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/CO2-4b288ae6a3594f778a5ed083b83a907c"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">3mm以下</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,770</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">0.3mm以上5mm未満</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥17,710</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">5mm</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥23,540</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== CO2レーザー ほくろ除去 ========== */}

            {/* =========== フラクショナルレーザー【にきび跡・クレーター】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_fractional_laser"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    フラクショナルレーザー【にきび跡・クレーター】
                  </h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">メニュー</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">顔</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥77,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥207,900</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥327,250</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両頬</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥44,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥118,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥187,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">鼻</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥22,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥59,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥93,500</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== フラクショナルレーザー【にきび跡・クレーター】 ========== */}

            {/* =========== 高濃度ビタミンC点滴25g ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_high_concentration_vitamin_C_25"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    高濃度ビタミンC点滴25g
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ビタミンCはコラーゲンの生成促進、メラニン生成の抑制、抗酸化作用、疲労回復・抗ストレス効果、免疫力向上、がん予防作用、生活習慣病改善、抗アレルギー作用、がん予防効果が期待されています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ビタミンCの血漿濃度は、およそ
                      400mg/日で飽和すると報告されていますが、ビタミンCは水溶性のため摂取量を増やしても余剰分は尿として排出され、大量に経口摂取しても血中濃度はある一定以上から上がりません。
                    </span>
                    <span className="c-doc-l-text__cell">
                      高濃度ビタミンC点滴により、血液中の濃度を急激に上げることで、経口摂取の数十倍の量が血管内に行き渡り、血中濃度を高めることにより、ビタミンCを必要とする各器官に直接行き渡らせることができます。
                    </span>
                    <span className="c-doc-l-text__cell">
                      そのため、経口摂取とは異なるレベルで上記の作用が期待できます。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      点滴時の血管痛、口渇（のどの渇き）、低血糖（めまい、冷や汗、疲労感等）、注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷、⾎管迷⾛神経反応、血管痛 等
                    </span>

                    <span className="c-doc-l-text__cell">
                      ※G6PD欠損症の方、腎不全があり透析中の方、透析の必要性が高い方、心不全や不整脈の方、浮腫・腹水のある方、ビタミンCでアレルギーが出たことのある方・または過敏症の方は施術を受けていただくことができません。
                    </span>

                    <span className="c-doc-l-text__cell">
                      ※初回、G6PD検査を行わせていただく場合がございます。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/C-25g-409106ad041d421aa528ab0f5dba27a7"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥11,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 高濃度ビタミンC点滴25g ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== シンデレラ点滴＋注射 ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_high_cinderella_infusion_injection"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    シンデレラ点滴＋注射
                  </h2>
                </div>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥4,950</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== シンデレラ点滴＋注射 ========== */}

            {/* =========== 白玉点滴 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_shiratama_drip"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">白玉点滴</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      白玉点滴とは、グルタチオンを主成分として配合した美容点滴です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      メラニン色素の抑制によるシミ予防と改善、美白、アンチエイジング効果、疲労・倦怠感の改善、肝機能向上、解毒、抗アレルギー作用、免疫高効果が期待されます。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷、⾎管迷⾛神経反応、血管痛 等
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/73d072c3a768448094a961bf5a5f48fe"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">レギュラー</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">高濃度</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥5,280</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,580</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 白玉点滴 ========== */}

            {/* =========== 血液クレンジング ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_blood_cleansing"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    血液クレンジング
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      100～200ccほどの少量の血液を採取し、医療用オゾンガスを投与して血液とオゾンを反応させることで、活性化した血液を再び身体の中に戻すという治療法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      細胞が活性化することで冷え性、肩こり、頭痛、美肌効果、認知症、脳血管障害などの予防や抗酸化作用、老化予防、体力・免疫力向上、代謝アップが期待できます。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      一時的なだるさ、クエン酸ナトリウム又はヘパリンナトリウムの使用によるショック
                      アナフィラキシー、血小板減少、HIT等に伴い血小板減少、血栓症、痺れ
                      注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着
                      感染症、神経損傷、⾎管迷⾛神経反応 等
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/ae21f447e1224c669d309470d720071e"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,200</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== 血液クレンジング ========== */}

            {/* =========== にんにく注射 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_garlic_injection"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">にんにく注射</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      アリナミン(ビタミンB1)を主成分として配合している液体を注射器を使って静脈に投与する施術です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      疲労回復、二日酔いの回復、免疫力UP、肩こり・腰痛の改善効果が期待できます。
                    </span>
                    <span className="c-doc-l-text__cell">
                      アリナミン（ビタミンB1）はにんにくの成分であり、注射中にんにくのような臭いを感じるため、にんにく注射と呼ばれています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      治療回数は、成人に対し1日1回10mlを週1回が目安です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      悪心、嘔吐、舌炎、頻尿、頭痛、下痢、掻痒感、蕁麻疹、注射部位及び周囲の疼痛
                      内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷
                      ⾎管迷⾛神経反応 等
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/da6053f661034e0d9b6fe64c7e238ab4"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1A</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥1,100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== にんにく注射 ========== */}

            {/* =========== プラセンタ注射 ラエンネック ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_placenta_injection_laennec"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    プラセンタ注射 ラエンネック
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ヒト胎盤由来成分（胎盤酵素分解物の水溶性物質）とpH調整剤が含まれたラエンネックを皮下又は筋肉内に注射することで慢性肝疾患における肝機能の改善が期待されます。
                    </span>
                    <span className="c-doc-l-text__cell">
                      その他薬効・薬理として、肝再生促進作用、肝細胞DNA合成促進作用、実験的肝障害抑制作用、抗脂肝作用、肝繊維増殖の抑制作用があります。
                    </span>
                    <span className="c-doc-l-text__cell">
                      治療回数は、成人に対し1日1回2ml～10mlを毎日又は隔日が目安です。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ヒト胎盤を原材料としていることに由来する感染症伝播のリスクを完全に排除することはできません。
                    </span>
                  </p>

                  <div className="c-doc-table -l">
                    <div className="c-doc-table__inner">
                      <div className="c-doc-table__cell">
                        <p className="c-doc-table__cell__title">
                          副作用
                          <span className="c-doc-table__cell__adjust-text">
                            :
                          </span>
                        </p>
                        <p className="c-doc-table__cell__text">
                          注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷、⾎管迷⾛神経反応、頭痛、肝機能障害（AST、ALTの上昇）等
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/32f43ff01e8d41709fb8f6fa1d4031a2"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">1A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥1,100</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">2A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥1,980</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">3A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥2,970</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">10A＋P1A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥10,890</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">20A＋P3A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥19,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== プラセンタ注射 ラエンネック ========== */}

            {/* =========== プラセンタ注射 メルスモン ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_placenta_injection_mersmon"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    プラセンタ注射 メルスモン
                  </h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ヒト胎盤由来成分（胎盤絨毛分解の水溶性物質）とベンジルアルコールが含まれたメルスモンを皮下又は筋肉内に注射することで更年期障害、乳汁分泌不全が期待できます。
                    </span>
                    <span className="c-doc-l-text__cell">
                      その他薬効・薬理として、組織呼吸促進作用、創傷治癒促進作用、抗疲労作用、硝子体及び球血膜下出血の吸収促進作用があります。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※まだ充分明らかではない、メルスモンの作用機序は上記のような諸作用が認められておりこれらの多様な生物学的活性作用が広凡な生体過程への賦活作用を示し、組織細胞の新陳代謝を高め、身体の以上状態を正常化するものと推測されています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      治療回数は成人に対し1日1回2ml～10mlを毎日又は隔日が目安となります。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ヒト胎盤を原材料としていることに由来する感染症伝播のリスクを完全に排除することはできません。
                    </span>
                  </p>

                  <div className="c-doc-table -l">
                    <div className="c-doc-table__inner">
                      <div className="c-doc-table__cell">
                        <p className="c-doc-table__cell__title">
                          副作用
                          <span className="c-doc-table__cell__adjust-text">
                            :
                          </span>
                        </p>
                        <p className="c-doc-table__cell__text">
                          注射部位及び周囲の疼痛、内出血、赤み、腫れ、発疹、不快感、色素沈着、感染症、神経損傷、⾎管迷⾛神経反応 等
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/a3125e850a714c029dfe79aa6b16dc04"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">ブラン</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">1A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥1,320</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">2A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥2,200</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">3A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,410</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">10A＋P1A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥12,100</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">20A＋P3A</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥24,200</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== プラセンタ注射 メルスモン ========== */}

            {/* =========== ボトックス ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_botox"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">ボトックス</h2>
                </div>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">【A】より１ヶ所</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥26,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">【A】より３ヶ所</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">3ヶ所＋1ヶ所※１</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥14,850</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">エラ縮小</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥63,800</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  【A】眉間、額、目尻、バニーズライン｟鼻のシワ｠、マリオネットライン｟口角下｠、オトガイ｟アゴ梅干し｠より１ヶ所
                </p>
                <p className="c-text u-pc-mt05 u-sp-mt05">
                  ※１）３ヶ所施術されるの方で、さらに１ヶ所追加される方
                </p>
              </div>
            </section>
            {/* =========== ボトックス ========== */}

            {/* =========== ボトックス【多汗症】 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_botox_first"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ボトックス【多汗症】
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">初回</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">両脇</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥60,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== ボトックス【多汗症】 ========== */}

            {/* NOTE: 2021/12/29 許可がでるまでコメントアウト */}
            {/* =========== HARG療法 ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_harg_therapy"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">HARG療法</h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">トライアル</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">前頭部</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥55,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥99,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥297,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥528,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">全頭部</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥121,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥121,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥363,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥660,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== HARG療法 ========== */}

            {/* =========== ﾏｲﾔｰｽﾞ・ｶｸﾃﾙ点滴 ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_royal_acrylic_infusion"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    ﾏｲﾔｰｽﾞ・ｶｸﾃﾙ点滴
                  </h2>
                </div>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥7,500</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== ﾏｲﾔｰｽﾞ・ｶｸﾃﾙ点滴 ========== */}

            {/* =========== ピアス ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_piercing_medical_stainless"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">ピアス</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ピアッシング専用器具（ピアスガン）を用いて耳たぶへピアスの穴あけをする医療行為です。
                      金属アレルギーの方でもアレルギー対応素材を使用したファーストピアスもご用意しています。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※感染症リスクを下げるため抗生物質を3日分処方します。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      鈍痛、化膿、感染症、ピアスの埋没、表皮嚢腫、血管迷走神経反射
                    </span>
                    <span className="c-doc-l-text__cell">
                      ※金属アレルギーの方はお申し出ください。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/7fdba0e394ad4d81bb6bdcb8fa3d1405"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">デザイン</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">両耳</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">片耳</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">ステンレス</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,850</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,080</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">チタン</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥4,950</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,960</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">透明</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥4,400</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,520</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="c-text u-pc-mt20 u-sp-mt10">
                  ※抗生物質(ｾﾌｿﾞﾝ）別途￥770必要となります
                </p>
              </div>
            </section>
            {/* =========== ピアス ========== */}

            {/* =========== ミラドライ ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_mira_dry"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">ミラドライ</h2>
                </div>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">治療法の内容</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      ミラドライは切らないわき汗治療器として皮膚を切ることなくわきが・多汗症治療を行える機械です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      マイクロ（電磁）波を患部にあてて、ワキガの原因となる汗腺をターゲットとし、アポクリン汗腺・エクリン腺にダメージを与える治療方法です。
                    </span>
                    <span className="c-doc-l-text__cell">
                      ダメージを受けた汗腺は発汗機能が停止するため、長期間の効果の実感が期待できます。
                    </span>
                  </p>
                </section>

                <section className="c-doc-l-title__section">
                  <h3 className="c-doc-l-title">リスク・副作用</h3>

                  <p className="c-doc-l-text">
                    <span className="c-doc-l-text__cell">
                      浮腫み、痛み、色素沈着、硬化、感覚の変化、発赤、熱傷、一時的な治療部位（腕）やその周辺の筋肉及び指の力減少、一時的なあざ、患部の突っ張り感
                    </span>
                    <span className="c-doc-l-text__cell">
                      運動はしばらく控えて下さい。
                      入浴は翌日から、シャワーは当日から可能です。
                      ただし、治療部位を過剰に濡らしたり温めるのはお控えください。
                      痛みや腫れを緩和するために治療後2-3日は、定期的にアイスパックを脇の下にはさんで冷やして下さい。
                    </span>
                  </p>
                </section>

                <p className="c-doc-link-text">
                  <a
                    className="c-doc-link-text__link"
                    href="https://spectacle.notion.site/6f57634bc44844e193c8c1ea027ab91c"
                    target="_blank"
                  >
                    詳細はこちら
                  </a>
                </p>

                <div className="c-table -col2">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">1パス</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥356,400</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">2パス</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥407,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            年間保証あり／２回まで施術可能
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥440,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== ミラドライ ========== */}

            {/* =========== PRP 血小板療法1cc ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="male_prp_platelet_therapy_1cc"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    PRP 血小板療法1cc
                  </h2>
                </div>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥66,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== PRP 血小板療法1cc ========== */}

            {/* =========== わきのくすみ・色素沈着（黒ずみ）治療 ========== */}
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_piercing_medical_standard"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    わきのくすみ・色素沈着（黒ずみ）治療
                  </h2>
                </div>

                <div className="c-table -col3">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">メニュー名</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">10回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">ピーリング</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥6,050</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">レーザートーニング</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥8,800</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥38,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥77,000</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">ビタミンCイオン導入</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥2,200</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            トラネキサム酸イオン導入
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥3,850</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">
                            イオン導入セット（ビタミンC・トラネキサム酸　５回ずつ）
                          </p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥29,700</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* =========== わきのくすみ・色素沈着（黒ずみ）治療 ========== */}

            {/* =========== メソラインスリム ========== */}
            {/* <section
              className="l-section-lv2 l-main-content-hero"
              id="male_meso_line_slim"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    メソラインスリム
                  </h2>
                </div>

                <div className="c-table -col1">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">3回セット</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      <div className="c-table__body__row" key={v4()}>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥13,500</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥35,000</p>
                        </div>
                        <div className="c-table__body__item">
                          <p className="c-table__text">¥50,000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* =========== メソラインスリム ========== */}
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
};

BeautyMedicalTreatmentPageTemplate.propTypes = {
  title: PropTypes.string,
};

const checkHash = () => {
  let hash = window.location.hash;
  if (hash === "#female-pico-laser") {
    SmoothScrolling.scrollTo("female_pico_laser", null);
  }

  if (hash === "#female-q-switch-ruby-laser") {
    SmoothScrolling.scrollTo("female_q_switch_ruby_laser", null);
  }

  if (hash === "#female-first-laser-facial") {
    SmoothScrolling.scrollTo("female_first_laser_facial", null);
  }

  if (hash === "#female-e-plus-sr") {
    SmoothScrolling.scrollTo("female_e_plus_sr", null);
  }

  if (hash === "#female-e-p-q-age-evolution-plus") {
    SmoothScrolling.scrollTo("female_e_p_q_age_evolution_plus", null);
  }

  if (hash === "#female-vitamin-c-ion") {
    SmoothScrolling.scrollTo("female_vitamin_c_ion", null);
  }

  if (hash === "#female-tranexamic-acid-ion") {
    SmoothScrolling.scrollTo("female_tranexamic_acid_ion", null);
  }

  if (hash === "#female-ion-introduction") {
    SmoothScrolling.scrollTo("female_ion_introduction", null);
  }

  if (hash === "#female-hifu-01") {
    SmoothScrolling.scrollTo("female_hifu_01", null);
  }

  if (hash === "#female-hifu-02") {
    SmoothScrolling.scrollTo("female_hifu_02", null);
  }

  if (hash === "#female-hifu-03") {
    SmoothScrolling.scrollTo("female_hifu_03", null);
  }

  if (hash === "#female-laser-toning") {
    SmoothScrolling.scrollTo("female_laser_toning", null);
  }

  if (hash === "#female-iontophoresis-bitamin-c") {
    SmoothScrolling.scrollTo("female_iontophoresis_bitamin_c", null);
  }

  if (hash === "#female-intragen") {
    SmoothScrolling.scrollTo("female_intragen", null);
  }

  if (hash === "#female-pore-lift-up-aging") {
    SmoothScrolling.scrollTo("female_pore_lift_up_aging", null);
  }

  if (hash === "#female-hydra-facial-01") {
    SmoothScrolling.scrollTo("female_hydra_facial_01", null);
  }

  if (hash === "#female-hydra-facial-02") {
    SmoothScrolling.scrollTo("female_hydra_facial_02", null);
  }

  if (hash === "#female-dermapen") {
    SmoothScrolling.scrollTo("female_dermapen", null);
  }

  if (hash === "#female-dermapen") {
    SmoothScrolling.scrollTo("female_dermapen", null);
  }

  if (hash === "#female-v-shape") {
    SmoothScrolling.scrollTo("female_v_shape", null);
  }

  if (hash === "#female-dermapen-option") {
    SmoothScrolling.scrollTo("female_dermapen_option", null);
  }

  if (hash === "#female-carbon-peeling-02") {
    SmoothScrolling.scrollTo("female_carbon_peeling_02", null);
  }

  if (hash === "#female-chemical-peeling") {
    SmoothScrolling.scrollTo("female_chemical_peeling", null);
  }

  if (hash === "#female-botox") {
    SmoothScrolling.scrollTo("female_botox", null);
  }

  if (hash === "#female-q-ruby-laser-stain") {
    SmoothScrolling.scrollTo("female_q_ruby_laser_stain", null);
  }
};

const BeautyMedicalTreatmentPage = ({ data }) => {
  const { markdownRemark: post } = data;

  useEffect(() => {
    checkHash();
  });

  return (
    <Layout>
      <BeautyMedicalTreatmentPageTemplate title={post.frontmatter.title} />
      <ShareInfomationPageTemplate />
    </Layout>
  );
};

BeautyMedicalTreatmentPage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default BeautyMedicalTreatmentPage;

export const beautyMedicalTreatmentPageQuery = graphql`
  query BeautyMedicalTreatmentPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
